$primary_color: #DA4234;
$secondary_color: #FFF2CA;
$border_color: #999999;
$ok_color: #428A34;
$ng_color: #CC3333;
$text_color: #403B2A;
$reserved_color: #428A34;
$available_color: #666666;

* {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-box-shadow: none;
	box-shadow: none;	 
	outline: none;	
}

html {
	background-color:#F7F7F8;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.root {
	padding:1em;
	padding-top: 4.5em;
	height:100%;
	box-sizing:border-box;
	position:relative;
	line-height: 1.5em;
	overflow-y:auto;
}

.miniRoot {
	padding:1em;
	padding-top: 4.5em;
	width: 100%;
	max-width:480px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}

.smallRoot {
	padding:1em 0;
	padding-top: 0em;
	width: 100%;
	max-width:600px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}


.mediumRoot {
	padding:1em 0;
	padding-top: 0em;
	width: 100%;
	max-width:1280px;
	margin:0 auto;
	box-sizing:border-box;
	line-height: 1.5em;
}

.noStageRoot {
	padding-top:2em;
}
.modal {
	position:absolute;
	width:100%;
	height:100%;
	max-width:460px;
	max-height:620px;
	background-color:#111111;
	box-sizing:border-box;
}

.modalRoot {
	height:100%;
	box-sizing:border-box;
	overflow-x:hidden;
	overflow-y:auto;
	padding-top: 1em;
	line-height: 1.4em;
	padding-right: 1em;
	padding-left: 1em;
	border:1px solid #CCCCCC;
}

.modalHead {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding:0em;
	padding-top:0.1em;
	line-height:1em;
	box-sizing:border-box;
	background-color:#393F4F;
	z-index:999;
}

.dialogRoot {
	box-sizing:border-box;
	padding: 1em;
	color: #CCCCCC;
	border:1px solid #CCCCCC;
}

.confirmRoot {
	box-sizing:border-box;
	min-width:280px;
	//padding: 1em 0;
	//border:1px solid #CCCCCC;
	h2 {
		margin:-1em -1.5em;
		padding:0.5em 1em;
		margin-bottom:1em;
		//background-color:$text_color;
		//color:$secondary_color;
		color:$text_color;
		background-color:#EEEEEE;
		//border-bottom:1px dashed $text_color;
		font-size:100%;
	}
	.formName {
		padding-top:0.75em!important;
		font-weight:bold;
		//color: red;
	}
	.formValue {
		padding-top:0.75em!important;
	}
}

.sampleRoot {
	box-sizing:border-box;
	//padding: 1em;
	//border:1px solid #CCCCCC;
	//overflow-y:auto;
	//max-height:100%;
	h2 {
		margin:-1em -1.5em;
		padding:0.5em 1em;
		margin-bottom:1em;
		color:$text_color;
		background-color:#EEEEEE;
		font-size:100%;
		span {
			font-size:90%;
		}
	}
	div.btns {
		margin-top:1em;
		text-align:center;
	}
	div.hint {
		font-size:90%;
		margin-top:0.5em;
		color:$primary_color;
	}
	div.sampleMail {
		h3 {
			color:$primary_color;
			font-size:100%;
		}
	}
}

.alert {
	position:absolute;
	width:100%;
	max-width:240px;
	background-color:#333333;
	color: #000000;
	box-sizing:border-box;
}

.sample {
	position:absolute;
	width:100%;
	max-width:600px;
	background-color:#FFFFFF;
	color: $text_color;
	box-sizing:border-box;
	max-height:100%;
	overflow-y:auto;
}

.bottomApp {
	position:fixed;
	bottom:1em;
	left:0;
	box-sizing:border-box;
	width:100%;
	padding:0 1em;
}

div.nextBtns {
	text-align:center;
	margin-top:1em;
}


.withIcon {
	display:inline-flex;
	vertical-align:middle;
}

.clickable {
	cursor:pointer;
}

.wide {
	width:100%;
}

.btn {
	border:1px solid white!important;
	color:white!important;
}

.paper {
	padding:1em 1em;
	padding-bottom:6em;
	background-color:white;
	//border-radius:0.5em 0.5em 0 0;
	line-height:1.8em;
	font-size:90%;
}

.topPaper {
	padding-bottom:1em;
}

.bottomButtons {
	margin:1em 0;
	margin-top:2em;
	text-align:center;
}

.paperTitle {
	margin-top:1em;
	//margin-bottom:2em;
	margin-bottom:1em;
	color:$primary_color;
}

.talk {
	margin-bottom:2em;
	p {
		margin-bottom:1em;
	}
}

.calendar {
	position:absolute;
	width:100%;
	min-width:300px;
	max-width:360px;
	padding-top:2em;
	background-color:#FFFFFF;
	box-sizing:border-box;
}

.base {
	position:relative;
}

.removeBtn {
	text-align:right;
	cursor:pointer;
	z-index:999;
}

.ok {
	color:$ok_color;
}
.ng {
	color:$ng_color;
}

.noWrap {
	white-space:nowrap;
}

div.colData {
	div.name {
		background-color:#EEEEEE;
		padding:0 0.5em;
		font-weight:400;
	}
	div.value {
		padding:0.5em;
		margin-bottom:1em;
	}
	div.name_w {
		background-color:#FFFFFF;
		padding:0;
		font-weight:700;
	}
	div.value_w {
		padding:0;
		margin-bottom:1em;
	}
}

.formControl {
	margin-bottom:3em!important;
	.formLabel {
		font-weight:bold!important;
	}
	.formValue {
		margin-top:0.25em!important;
		color:$text_color;
	}
}
.formControlDense {
	margin-bottom:0em!important;
}

#login {
	.mailLoginInput {
		border:1px solid $border_color;
		border-radius:0.5em;
		padding:1em;
		margin-top:1em;
	}	
}

#stage_detail {
	div.stageRight {
		border:1px solid #E5E5E5;
		border-radius:0.5em;
		padding:0.5em 1em;
		padding-bottom:4em;
		margin-bottom:1em;
		background-color:#F5F5F5;
		div.stageStatus {
			margin-bottom:2em;
			text-align:center;
		}
		div.btns {
			padding:0.5em 0;
			text-align:right;
		}
	}
	div.stageRange {
		border:1px solid $border_color;
		padding:0em 1em;
		padding-bottom:1em;
		//border-radius:0.5em;
	}
	div.stageRangeNotice {
		ul {
			margin:1em 0;
			padding:0;
		}
		li {
			margin-left:1.5em;
			margin-bottom:1em;
			font-size:90%;
		}
	}
	div.cautions {
		color: red;
		margin-top:1em;
		font-size:90%;
	}
	.colName {
		font-weight:bold;
	}
	.stageTextBox {
		border:1px solid #CCCCCC;
		padding:0.5em 1em;
		padding-bottom:2em;
		margin-bottom:1em;
		//border-radius:0.5em;
		.name {
			font-weight:bold;
			font-size:95%;
			margin-bottom:1em;
		}
		.value {
			font-size:95%;
			line-height:1.5em;
		}
	}
}

.stageFormInput {
	position:absolute;
	width:100%;
	min-width:300px;
	max-width:640px;
	max-height:100%;
	padding-top:2em;
	background-color:#FFFFFF;
	box-sizing:border-box;
	overflow-y:auto;
	.formName {
		padding-top:0.8em!important;
		//color:$primary_color;
		font-weight:bold;
	}
	.formValue {
		padding-top:0.8em!important;
		padding-bottom:1em!important;
	}
	.formNameVertical {
		padding-top:0!important;
	}
	span.serial {
		margin:0 0.25em;
		color:$primary_color;
		font-weight:bold;
	}
	.canceled {
		text-align:center;
		color:#996666;
		padding:1em 0;
		background-color:#FFEEEE;
		span {
			font-size:80%;
		}
	}

}	

.stageInput {
	.formName {
		padding-top:0.8em!important;
		//color:$primary_color;
		font-weight:bold;
	}
	.formValue {
		padding-top:0.8em!important;
	}
	.formNameVertical {
		padding-top:0!important;
	}
	ul {
		margin:0;
		padding:0;
		list-style:none;
		margin-bottom:1em;
		li {
			margin:0;
			padding:0;
		}
	}
	.separate {
		border-bottom:1px solid $border_color;
		margin-bottom:0.5em;
	}
	.stageLimit {
		position:relative;
		text-align:right;
		padding-right:3em;
		span.btn {
			position:absolute;
			right:0;
			top:-0.20em;
		}
	}
	span.swOn {
		background-color: $ok_color;
		color: white;
		border-radius:1em;
		padding:0 0.75em;
	}
	span.swOff {
		background-color: $border_color;
		color: white;
		border-radius:1em;
		padding:0 0.5em;
	}
	span.soldout {
		color:$ng_color;
	}
	span.available {
		color:$available_color;
		font-size:90%;
	}
	span.reserved {
		color:$reserved_color;
	}
}

.stageForms {
	.stageForm {
		position:relative;
		margin-top:0.5em;
		padding:1em;
		padding-bottom:1em;
		border:1px solid $border_color;
		//border-radius:0.5em;
		div.name {
			.icon {
			margin-right:0.5em;
			}
		}
		div.url {
			position:relative;
			font-size:80%;
			color:#000000;
			margin:0;
			padding:0;
			padding-bottom:0.5em;
			line-height:1.3em;
			margin-top:1em;
			border-bottom:1px solid silver;
			div.clip {
				position:absolute;
				top:-0.25em;
				right:0;
			}
		}
		div.urlForm {
		}
		div.urlLimited {
			span {
				color:gray;
			}
		}
		div.bccmail {
			font-size:80%;
			color:#333333;
			margin:0;
			padding:0;
			margin-top:1em;
			line-height:1em;
		}
		div.noavailable {
			font-size:80%;
			color: #DA4234;
		}
		div.actions {
			font-size:90%;
			text-align:right;
			margin-top:1em;
		}
		div.edit {
			position:absolute;
			right:0.5em;
			top:0.25em;
		}
	}
}

.pageTitle {
	position:relative;
	margin-bottom:1em;
	padding:0em 0em;
	padding-bottom:2.5em;
	h2 {
		margin:0;
		padding:0;
		line-height:1em;
		font-size:180%;
		color:$secondary_color;
		span {
			text-align:left;
			font-size:70%;
			color:$secondary_color;
		}
	}
	h3 {
		margin:0;
		padding:0;
		line-height:1em;
		font-size:140%;
		//color:$secondary_color;
		color:$text_color;
	}
	div.btns {
		position:absolute;
		top:0em;
		right:0em;
	}
	div.refresh {
		position:absolute;
		bottom:-0.5em;
		left:-0.5em;
	}
}

.stageHeader {
	position:relative;
	margin-top:2em;
	//border-bottom:3px dashed silver;
	padding:0em 0em;
	padding-top:2em;
	background-color:#403B2A;
	.inner {
		position:relative;
		padding:0 1em;
		width: 100%;
		max-width:1280px;
		margin:0 auto;
		box-sizing:border-box;
		line-height: 1.5em;
		h2 {
			margin:0;
			padding:0;
			line-height:1.2em;
			font-size:180%;
			color:$secondary_color;
			padding-bottom:0.25em;
			p {
				text-align:left;
				font-size:70%;
				color:$secondary_color;
				line-height:1.1em;
				font-weight:normal;
				margin:0.5em 0;
				padding:0;
				opacity:0.9;
			}
		}
		div.accepting {
			padding:0;
			margin:0;
			margin-top:0.5em;
			padding-bottom:1.5em;
			span.notOpen {
				color:gray;
				border-color:gray;
			}
		}
		h3 {
			margin:0;
			padding:0;
			line-height:1em;
			font-size:140%;
			color:$primary_color;
		}
		div.btns {
			//position:absolute;
			//top:0em;
			//right:0.5em;
			font-size:95%;
			text-align:right;
			padding-bottom:1em;
			.current {
				background-color:#FFF2CA;
				color: #000000;
			}		
		}
	}
}


.bookingList {
	margin-top:1em;
	.secondLine {
		color:#999999;
	}
	.clickable {
		cursor:pointer;
	}
	.canceled {
		color:#996666;
	}
	.impact {
		font-weight:bold;
	}
	.impactName {
		color:$primary_color;
	}
	.mail {
		margin-right:0.25em;
		color: $ng_color;
	}
	.tel {
		margin-right:0.25em;
	}
	.mailChecked {
		color:$ok_color;
	}
}

div.csvBox {
	border:1px solid $border_color;
	padding:1em;
	margin-bottom:1em;
	border-radius:1em;
	h3 {
		margin:0;
		padding:0;
		color:$primary_color;
		margin-bottom:1em;
	}
	div.common {
		margin-bottom:1em;
	}
	div.text {
		margin-bottom:0.5em;
		font-size:90%;
	}
	div.form {
		margin-bottom:1em;
		ul {
			margin:0;
			padding:0;
			margin-top:1em;
			li {
				font-size:90%;
				margin-left:1.5em;
			}
		}
	}
	div.sampleBtns {
		margin-bottom:1em;
	}
}

span.empty {
	color:#666666;
}

div.topRight {
	background-color:white;
	padding:1em 1em;
}

div.topTitle {
	background-color:$text_color;
	padding:2em 0;
	padding-top:5em;
	text-align:center;
	h1 {
		color:$secondary_color;
		font-size:300%;
		margin:0;
		padding:0 20px;
		img {
			width:100%;
			max-width:400px;
		}
	}
	div.special {
		width:100%;
		max-width:400px;
		margin:0 auto;
		text-align:left;
		margin-top:2em;
	}
	div.add {
		margin-top:1em;
	}
}

div.topLeft {
	//background-color:$text_color;
	padding:0.1em 0.1em;
	padding-bottom:4em;
	div.refresh {
		padding:0.45em 0.5em;		
		padding-top:0.3em;
	}
	div.noStage {
		color:$text_color;
		padding:4em 0;
		padding-bottom:5em;
		text-align:center;
		border-bottom:1px dashed $text_color;
		font-size:90%;
	}
}

div.special {
	position:relative;
	margin-top:1em;
	margin-bottom:1em;
	img {
		width:100%;
		border-radius:0.5em;
	}
	div.text {
		color:$secondary_color;
		padding:0 1em;
		box-sizing:border-box;
		font-size:90%;
		margin-top:1em;
		p {
			font-size:90%;
			text-align:center;
		}
		ul {
			margin:0;
			padding:0.5em;
			margin-top:0.5em;
			padding-bottom:0.8em;
			border:1px solid $secondary_color;
			list-style:none;
			li {
				margin:0;
				padding:0;
				margin-left:0.5em;
				line-height:1.5em;
				font-size:90%;
			}
		}
	}
}

.stages {
	border-bottom:1px dashed $text_color;
	div.noStage {
		position:relative;
		padding:0.5em 0;
		padding-bottom:3em;
		margin-bottom:10em;
		//background-color:$secondary_color;
		//border:0.1em solid $secondary_color;
		//border-bottom:1px solid $border_color;
		line-height:2em;
		color:$secondary_color;
		//color:$text_color;
		text-align:center;
		font-size:90%;
	}
	div.stage {
		position:relative;
		//background-color:$secondary_color;
		padding:1em 1em;
		padding-bottom:1.5em;
		margin-bottom:0.1em;
		border-top:1px dashed $text_color;
		div.text {
			//color:$secondary_color;
			color:$text_color;
			cursor:pointer;
			div.title {
				//color:$secondary_color;
				color:$text_color;
				//border-bottom:3px solid $primary_color;
				//color: $text_color;
				margin-bottom:0em;
				font-size:120%;
				font-weight:bold;
				line-height:1.2em;
			}
			div.organizer {
				//color: $text_color;
				font-size:70%;
				margin-bottom:0.5em;
			}
			div.date {
				font-size:90%;
			}
			div.place {
				font-size:90%;
			}
		}
		div.btn {
			position:absolute;
			top:0;
			right:0;
		}
		div.actions {
			position:absolute;
			bottom:0.8em;
			right:0.5em;
		}
	}
}

div.infos {
	//margin-top:2em;
	//padding-right:2em;
	h3 {
		margin:0;
		padding:0;
		line-height:1em;
		margin-bottom:1em;
		font-size:90%;
		color:$text_color;
		//text-align:center;
	}
	div.info {
		//margin-bottom:4em;
		margin-bottom:1em;
		//background-color:#F5F5F5;
		border:1px solid silver;
		padding:1em 1em;
		padding-bottom:3em;
		font-size:80%;
		border-radius:0.5em;

		div.title {
			color:$text_color;
			font-weight:bold;
			font-size:120%;
			border-bottom:1px solid $text_color;
		}
		div.text {
			margin-bottom:1em;
		}
		div.date {
			text-align:right;
			color:gray;
		}
	}
}
h3.sep {
	color:$text_color;
	border-bottom:1px solid $text_color;
}

div.verBox {
	text-align:center;
	border:1px solid silver;
	padding:1em;
	h3 {
	  font-size:90%;
	  font-weight:normal;
	  color:#333333;
	  margin:0;
	}
	div {
	  font-size:180%;
	}
	div.latest {
	  color: green;
	}
	div.current {
	  color: pink;
	}
}
  
div.verGuide {
	margin-bottom:2em;
	div {
		font-size:90%;
		p {
		font-size:80%;
		color:#888888;
		}
	}
}

.notice {
	color: red;
}

.link {
	color:$primary_color;
	text-decoration:none;
}

.decn {
	text-decoration:none;
}

.help {
	padding-top:3em;
	h2 {
		text-align:center;
		color:$text_color;
		margin-bottom:2em;
	}
	div.menus {
		padding:0 1em;
	}
	.link {
		text-decoration:none;
	}
	div.menuBox {
		border:1px solid $primary_color;
		border-radius:0.3em;
		padding:1em;
		padding-bottom:2em;
		min-height:100%;
		box-sizing:border-box;
		h3 {
			margin:0;
			padding:0;
			margin-bottom:1em;
			color:$primary_color;
			font-weight:normal;
		}
		div.text {
			font-size:90%;
			color:$text_color;
		}
	}
	div.contact {
		text-align:center;
		margin-top:6em;
		margin-bottom:3em;
	}
	div.menu2 {
		text-align:center;
		margin-top:4em;
		.link {
			color:$text_color;
		}
	}
	.copyright {
		text-align:center;
		margin-bottom:3em;
	}
	.cardRoot {
		margin-bottom:3em;
		div.img {
			text-align:center;
			padding:1em 0;
			background-color:#EEEEEE;
			img {
				//border:1px solid silver;
				filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.6));
				width:70%;
				max-width:320px;
				min-width:300px;
			}
		}
	}
	.cardRootIndex {
		height:100%;
		cursor:pointer;
		margin-bottom:1em;
	}
}

.popperAnswer {
	border:1px solid $border_color;
	background-color:white;
	padding:0.5em 1em;
	filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.6));
	max-width:240px;
	z-index:9999;
}

.documentHelp {
	margin-bottom: 1em; 
	dt {
		border-bottom: 1px solid silver;
		margin-bottom: 0.5em;
		font-size: 1em;
		font-weight:bold;
	}
	dd {
		margin: 0;
		padding: 0;
		padding-left: 0.5em;
		margin-bottom: 2em;
		ol {
			margin: 0.5em 0;
			padding: 0;
			li {
				margin: 0;
				padding: 0;
				margin-left: 2em;
				margin-bottom: 0.5em;
			}
		}
		ul {
			margin: 0.5em 0;
			padding: 0;
			li {
			  margin: 0;
			  padding: 0;
			  margin-left: 2em;
			  margin-bottom: 0.5em;
			}
		}
	}
	.link {
		color:$text_color;
		text-decoration:underline;
	}
}

.signature {
	text-align: right;
}

div.accepting {
	margin-top:2em;
	span {
		//background-color:$secondary_color;
		color:$text_color;
		border-radius:1em;
		padding:0.25em 1em;
	}
	span.active {
		background-color:$primary_color;
		color:$secondary_color;
		font-weight:bold;			
	}
	span.before {
		border:1px solid $primary_color;
		color:$primary_color;
		padding:0.25em 0.5em;
	}
	span.after {
		border:1px solid #999999;
		color:#999999;
	}
	span.notOpen {
		border:1px solid $text_color;
		color:$text_color;
	}
}

div.noticeAgree {
	color: #DA4234;
	padding-left:1em;
}

div.mailerLife {
	text-align:center;
	font-size:90%;
	margin-bottom:2em;
	color: #333333;
}

div.mailerCaution {
	//border:1px solid #DA4234;
	background-color:#FFEEEE;
	padding:1em;
	margin-bottom:3em;
	p {
		font-size:90%;
	}
	h3 {
		font-weight:normal;
		font-size:100%;
		text-align:center;
		padding:0;
		margin:0;
	}
	div.check {
		margin-top:2em;
		text-align:center;
	}
}
