.appbar {
    //background-color:#B2DFDB!important;
    background-color:#DA4234!important;
    .personButton {
        padding-right:0!important;
        padding-left:0!important;
		color: #FFF2CA!important;
    }
    div.menuPerson {
        text-align:right;
        position:absolute;
		right:0.5em;
	}
	.home {
		font-size:180%;
		color: #FFF2CA!important;
		margin-left:0.25em;
	}
	.personMenu {
		h3 {
			font-size:100%;
			margin:0;
			padding:0;
			margin-bottom:0.5em;
		}
	}
	.personData {
		list-style:none;
		margin:0;
		padding:0;
		margin-bottom:2em;
		li {
			margin:0;
			padding:0;	
		}
	}
}

.toolbar {
    padding-left:0.5em!important;
}
